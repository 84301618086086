import React, {useEffect} from "react";

//import HelloWorld from 'sg-frontend-shared/src/components/HelloWorld';
//import { useDataStore, useSessionStorage, useLocalStorage } from 'sg-frontend-shared/src/hooks/useStorage';

import { Link } from "react-router-dom";
import { useAuth } from "sg-frontend-shared/src/hooks/useAuth";
import * as GuideInfo from 'sg-frontend-shared/src/components/GuideInfo';
import * as Functions from 'sg-frontend-shared/src/components/Functions';
import { useTranslation } from "../translations/i18next";

function Home() {
  //const [dataStoreCounter, setDataStoreCounter] = useDataStore('counter');
  //const [sessionStorageCounter, setSessionStorageCounter] = useSessionStorage('counter', 0);
  //const [localStorageCounter, setLocalStorageCounter] = useLocalStorage('counter', 0);
  const { doLogin } = useAuth();
  const {t} = useTranslation();

  //const usenativeStorage = localStorage.getItem('usenative');
  const usenative =  (Functions.QueryValue('native')) ? true:false;
  const querylogin =  (Functions.QueryValue('login')) ? true:false;

  // empty data from installer
  localStorage.setItem("tenantId","");
  localStorage.setItem("usenative", usenative);

  useEffect(()=>{

    if(querylogin) {
      doLogin();
    }

  },[querylogin, doLogin]);

  const installLogin = () => {
    window.history.pushState('', '', '?native=1&nativecall=install');
    window.ReactNativeWebView?.postMessage("navigate:::installer")
  }

  const ble = () => {
    //window.location.replace('?native=1&nativecall=ble');
    //window.location.replace('#callble');
    window.history.pushState('', '', '?native=1&nativecall=ble');
    window.ReactNativeWebView?.postMessage("navigate:::ble")
  }

  return(
    <div className="mx-auto w-50 auto-100 relative pb-5 text-center">
      {querylogin === false &&
        <>
          <div className="mt-5">
            <GuideInfo.Footer />
          </div>

          <div className="mt-6">
            {usenative ?
              (
                <>
                  <div>
                    <button onClick={() => installLogin()} className="relative p-4 block w-80 btn content-box">
                        <h5 className="bold">{t("home.install")}</h5>
                        <div dangerouslySetInnerHTML={{__html: t("home.installerLogin")}}/>
                        <div className="p-2 mt-4 w-80 btn btn-light">
                          <i className="fa fa-lock" aria-hidden="true"></i> {t("home.login")}
                        </div>
                    </button>
                  </div>

                  <div className="mt-5">
                    <button onClick={() => ble()} className="relative p-4 block w-80 btn content-box">
                      <h5 className="bold">{t("home.settings")}</h5>
                      <div dangerouslySetInnerHTML={{__html: t("home.pairDevice")}}/>
                      <div className="p-2 mt-4 w-80 btn btn-light">
                        <i className="fa fa-bluetooth" aria-hidden="true"></i>  {t("home.pair")}
                      </div>
                    </button>
                  </div>
                </>
            ):(
                <>
                  <Link onClick={() => doLogin()} className="relative p-4 block w-80 btn content-box">
                        <h5 className="bold">{t("home.install")}</h5>
                        <div dangerouslySetInnerHTML={{__html: t("home.installerLogin")}}/>
                        <div className="p-2 mt-4 w-80 btn btn-light">
                        <i className="fa fa-lock" aria-hidden="true"></i> {t("home.login")}
                      </div>
                  </Link>
                </>
              )
            }


            <div className="mt-10 text-sm text-grey">
              <br/>
              <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
             2.03 (API 1.0)
            </div>
          </div>
      </>}
    </div>
  );
}

export default Home;

import React, {
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import sv from "./locales/sv";
import fi from "./locales/fi";

export const LANGUAGES = [
   {
    code: "sv",
    name: "Svenska",
  },
   {
    code: "fi",
    name: "Suomea",
  },
];

i18next.use(LanguageDetector).init({
  supportedLngs: LANGUAGES.map((lang) => lang.code),
  fallbackLng: "sv",
  resources: {
    sv,
    fi,
  },
});

export const TranslationContext = createContext();

function TranslationProvider({ children }) {
  // eslint-disable-next-line no-unused-vars
  const [_, forceUpdate] = useState();
  const [showKeys, setShowKeys] = useState(false);

  useEffect(() => {
    i18next.on("languageChanged", forceUpdate);
  }, [forceUpdate]);

  const t = (key, options) => {
    const ret = i18next.t(key, options);
    if (!showKeys || typeof ret === "object") {
      if (ret === key) {
        console.warn(`translation not found for key: ${key}`);
      }
      return ret;
    }
    return key;
  };

  const value = {
    ...i18next,
    t,
    currentLanguage: LANGUAGES.find((lang) => lang.code === i18next.language),
    setShowKeys,
  };

  return (
    <TranslationContext.Provider value={value}>
      {children}
    </TranslationContext.Provider>
  );
}

function useTranslation() {
  const context = useContext(TranslationContext);
  if (context === undefined) {
    throw new Error("useTranslation must be used within a TranslationProvider");
  }
  return context;
}

export { TranslationProvider, useTranslation };

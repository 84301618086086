import { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "react-query";

import { useAPI } from 'sg-frontend-shared/src/hooks/useAssetAPI';

import * as Functions from 'sg-frontend-shared/src/components/Functions';
import * as GuideInfo from 'sg-frontend-shared/src/components/GuideInfo';
import { useTranslation } from "../translations/i18next";
import LanguagePicker from "../components/LanguagePicker";

export default function Install() {
  const TypeOfUser = "installer";
  const {t, currentLanguage} = useTranslation();

  const {callAPI, deviceModelAPI} = useAPI();
  const queryClient = useQueryClient();

  const tenantId = (localStorage.getItem("tenantId")) ? localStorage.getItem("tenantId"):'';
  const tenantRole = (localStorage.getItem("tenantRole")) ? localStorage.getItem("tenantRole"):'';
  const deviceModelsData = (localStorage.getItem("deviceModel")) ? localStorage.getItem("deviceModel"):"";

  const topbody = useRef();

  const [stateDeviceSearch, setDeviceSearch] = useState('');
  //const [stateAssetData, setAssetData] = useState([]);
  const [stateAssetId, setAssetId] = useState('');
  const [stateViewStep, setViewStep] = useState(1);
  const [stateStepGuide, setStepGuide] = useState(0);

  // guide state
  const [stateGuideFirst, setGuideFirst] = useState();
  const [stateGuideDem, setGuideDem] = useState();
  const [stateGuideCable, setGuideCable] = useState();
  const [stateGuideDrift, setGuideDrift] = useState();
  const [stateGuideInst, setGuideInst] = useState();
  const [stateGuideEnd, setGuideEnd] = useState();
  const [WarningModal, setWarningModal] = useState(true);

  const [stateLoading, setLoading] = useState(false);
  const [stateClientIdCheck, setClientIdCheck] = useState(false);
  const [stateSensorIdCheck, setSensorIdCheck] = useState(false);
  
  const [stateTempSensor, setTempSensor] = useState();
  const [stateDeleteTempsensorId, setDeleteTempsensorId] = useState();

  const [stateViewLoopSGA, setViewLoopSGA] = useState('');
  const [stateViewLoopSGAdesc, setViewLoopSGAdesc] = useState('');

  const [stateViewLoopSGT, setViewLoopSGT] = useState('');
  const [stateViewLoopSGTcount, setViewLoopSGTcount] = useState('');

   // use for loop
   const sleep = (ms) => new Promise((res) => setTimeout(res, ms));

  // info
  const EANNUM = "735999";

  var assetId = stateAssetId;
  var dataAsset = [];
  //var dataDeviceModel = [];

  // get query
  const step = (Functions.QueryValue('step')) ? Functions.QueryValue('step'):1;
  assetId = (Functions.QueryValue('id')) ? Functions.QueryValue('id'):assetId;
  const assetMethod = (assetId) ? 'patch':'post';


  // run once
  useEffect(()=>{
    // empty APi cache
    queryClient.removeQueries('tenant-asset');

    ScrollToTop();

    // failsafe
    // check if no tenantId go to start and get
    if (!deviceModelsData) {
        window.location.replace('/start');
    }
    if (!tenantId) {
        window.location.replace('/start');
    }
    if(tenantRole && tenantRole !== TypeOfUser) {
      window.location.replace('/start');
    }


  },[queryClient, tenantId, tenantRole, deviceModelsData]);

  const ScrollToTop = () => {
    window.scrollTo({top: topbody.current.offsetTop })
  }

  // Load data
  const getTenantAsset = async () => {
    if(assetId){
      const data = await callAPI({
        resource: `/tenant/${tenantId}/asset/${assetId}`,
        method: 'get',
      });
      console.log("ASSET",data);
      return data;
    } else {
      return [];
    }
  };
  //const { isLoading, isSuccess, isError, error, data } = useQuery('tenant-asset', getTenantAsset);
  const LoadAsset = useQuery('tenant-asset', getTenantAsset);

  // Get Data
  dataAsset = (LoadAsset.isSuccess) ? LoadAsset.data:[];
  const datadeviceModelId = (dataAsset && dataAsset.deviceModelId) ? dataAsset.deviceModelId:'';
  const dataClientId = (dataAsset && dataAsset.clientId) ? dataAsset.clientId:'';
  //const dataSensors = (dataAsset && dataAsset.sensors) ? dataAsset.sensors:'';
  const datameterdeliveryArea = (dataAsset && dataAsset.deliveryArea) ? dataAsset.deliveryArea:'';
  const datameterGridArea = (dataAsset && dataAsset.meterGridArea) ? dataAsset.meterGridArea:'';
  const dataAddress = dataAsset.address && dataAsset.address ? dataAsset.address:[];

  const [state, setState] = useState({
              meterId:'',
              meterIdError:'',

              address1:'',
              address:'',
              zip:'',
              city:'',

              gridAreaError:false,
              deliveryArea:datameterdeliveryArea,
              meterGridArea:datameterGridArea,

              deviceModelId:datadeviceModelId,
              docId:'',
              deviceModelError:'',
              searchmodel:'',
              deviceModel:'',

              hpType: '',
              hpManufacturer:'',
              hpModel:'',
              hpSize:'',
              hpModelId:'',
              hpStatus:false,

              clientId:dataClientId,
              clientIdError:'',
              clientIdChange:false,

              cableError:'',
              cable:0,

              sensorsdata:'',
              sensorId:'',
              sensorIdError:'',
              sensorIdAddStatus:false,
              sensorIdViewError:false,
              sensorInAction:false,

              assetId:'',
              assetIdStatus:false,
              assetIdStatusFalse:false,

              verifiedinAction:false,
              verifiedinActionError:false,
              verifiedinActionFinnish:false,

              stepTitle: t("install.installation"),
              loadedFirstTime: '',
              savedStatus:false,
              saveConfirm:false,
              loadType:'',
              btnNextTitle: t("install.nextStep"),
              endTitle: t("install.confirm"),

              viewError:'',
              viewInfo:'',
              viewDelete: false,
              viewStatus: '',
              apiresponse: '',
              itemData: '',         
              submitBtn: t("install.finishAndSave"),
              deleteBtn: t("install.remove"),
              customerName:'',
              
              hardwareStatus:false,
              sensorStatus:false,
              
              hpClickedItem:'',
              
              docguideId: '',
              docguideView: '',
              
              latitude:'',
              longitude:'',
              geostatus: '',
              posBtn: t("install.getPos"),
              
              viewQR:false,
              SearchHp:'hidden',
              ConnectionStatus:false,
              createdDate: dataAsset.createdDate
            });
            
            const btnNextTitle = t("install.nextStep");
            
            //
            // Use for reload data
            const apiAssetMutation = useMutation({
    mutationFn: (payload) => callAPI(payload),
    onSuccess: () => {
      // empty  data
      queryClient.removeQueries('tenant-asset');

      // Invalidate and refetch
      queryClient.invalidateQueries(['tenant-asset']);


    },
  });

  const reloadAsset = async () => {
    assetId = stateAssetId;

    // Use for reload data
    apiAssetMutation.mutate({
      resource: `/tenant/${tenantId}/asset/${assetId}`,
      method: 'get'
    });
  }


  //
  // Edit asset data
  const LoadEdit = async () => {

    // Check if id exists
    if(Functions.QueryValue('id')){
      // Return to start
      if(!dataAsset.assetId) {

      }
    }

    if(dataAsset && assetId && !state.loadedFirstTime) {
      var data = dataAsset;

      const long = (data.geoLocation && data.geoLocation.coordinates && data.geoLocation.coordinates[0]) ? data.geoLocation.coordinates[0]:'';
      const lat = (data.geoLocation && data.geoLocation.coordinates && data.geoLocation.coordinates) ? data.geoLocation.coordinates[1]:'';

      // cable view hasSgReady hasEVU
      var cable = '';
      if(data.control && data.control.hasEVU === true && data.control.hasSgReady === true) {
          cable = 2;
      } else if(data.control && data.control.hasEVU === true) {
          cable = 1;
      } else if(data && data.control && data.control.hasEVU === false && data.control.hasSgReady === false) {
          cable = 0;
      }
      
      // set
      setState(previousState => {return { ...previousState,
        meterId:data.meterId,
        cable:cable,
        deliveryArea: data.deliveryArea,
        meterGridArea: data.meterGridArea,
        longitude: long,
        latitude: lat,
        address:dataAddress.address1,
        zip:dataAddress.zip,
        city:dataAddress.city,
        loadedFirstTime: 1,
        createdDate: data.createdDate
      }});
    }

    //////////////////////
    // load data devicemodels
    if(datadeviceModelId){
      const data = await deviceModelAPI({
        deviceModelId:datadeviceModelId
      });

      const id = datadeviceModelId;
      const docs = (data.docs) ? data.docs : '';
      const langCode = currentLanguage.code === "fi" ? "FI" : "SE";
      let guideDocId = docs[langCode] ? docs[langCode] : (data.docId) ? data.docId : '';
      
      setState(previousState => {return { ...previousState,
                              hpType: data.type,
                              hpManufacturer: data.mfr,
                              hpModel: data.model,
                              hpSize: data.size,
                              hpModelId: id,
                              deviceModelId:id,
                              docId:guideDocId,
      }});
  
      GuideLoad(guideDocId);

    }

    // check for sensors
    if(data && data.sensors.length > 0 ) {
      getTempsensorStatus();
    }
  }


  // run when loaded
  useEffect(()=> {

    // set assetid
    if(assetId) {
      setAssetId(assetId);
    }

    // jump to step by query ?step=1
    if(step) {
      setViewStep(step);
    }


    if(LoadAsset.isSuccess) {
      LoadEdit();
      UpdateDataSensors();
    }

  // eslint-disable-next-line
},[LoadAsset.isSuccess, datadeviceModelId, currentLanguage]);


  //
  const HandleSubmitX = async (event) => {
        event.preventDefault();

  }

  //
  // METER ID
  const MeterIdValue = (s,end) => {
    const dataSaved = (dataAsset.meterId) ? dataAsset.meterId:'';
    const str = (state.meterId) ? state.meterId:dataSaved;
    const result = str.substring(s, end);
    return result;
  }

  const MeterIdKeyUp = (event) => {
    // options
    const keycode = event.keyCode;
    //const length = event.target.value.length;
    const form = event.target.form;
    const index = Array.prototype.indexOf.call(form, event.target);

    // if delete empty value
    if(keycode === 8){
      event.target.value = '';
      form.elements[index - 1].focus();
    } else {
      form.elements[index + 1].focus();
    }

    // empty errror msg
    setState(previousState => {return { ...previousState,
                                meterIdError:''
                              }});
    setLoading(false);

  }

  const HandleMeterId = async (e) => {
    assetId = stateAssetId;

    var meterIdStatus = true;
    var meterIdError = '';
    var respAPI = '';
    const form = document.forms.installForm;
    // Get val from box fields
    const mId = form.m1.value+
                form.m2.value+
                form.m3.value+
                form.m4.value+
                form.m5.value+
                form.m6.value+
                form.m7.value+
                form.m8.value+
                form.m9.value+
                form.m10.value+
                form.m11.value+
                form.m12.value;

    // send to API
    const meterId = EANNUM+mId;
    setState(previousState => {return { ...previousState,
                                meterId:meterId
                              }
                      });
    if(mId) {
      try {
        // new asset
        // comfortCategory: comfort, normal, price
        if(!assetId && mId) {
          respAPI = await callAPI({
              resource:'/tenant/'+tenantId+'/asset/',
              method: 'post',
              data: {
                      tenantId: tenantId,
                      comfortCategory: 'comfort',
                      meterId: meterId,
                      deliveryArea: state.deliveryArea,
                      meterGridArea: state.meterGridArea,
                    }
          });

          // set assetid if new
          if(respAPI && respAPI.assetId) {
            assetId = respAPI.assetId;
            setAssetId(respAPI.assetId);
          }
        }

        // update if exists
        if(assetId && mId) {
            await callAPI({
                resource:'/tenant/'+tenantId+'/asset/'+assetId,
                method: 'patch',
                data: {
                        tenantId: tenantId,
                        meterId: meterId,
                      }
            });
        }

        // set url with assetId
        if(assetId) {
          var newurl = window.location.pathname + '?id='+assetId;
          window.history.pushState({path:newurl},'',newurl);
        }

      } catch (error) {

        ScrollToTop();
        setLoading(false);
        meterIdStatus = false;
        meterIdError = (error && error.response && error.response.data) ? error.response.data.issues:'';

        setState(previousState => {return { ...previousState,
                                  meterIdError:meterIdError,
                                  }});
        console.error("#HandleMeterId error", meterIdError, error);

        // if assetId not exist
        if(meterIdError){
            meterIdError.forEach((err, i)=>{
              const errPath = err.path[0];
              if(errPath === 'assetId') {
                // empty frontend assetId
                setAssetId('');
                var newurl = window.location.pathname + '?id=';
                window.history.pushState({path:newurl},'',newurl);

                setState(previousState => {return { ...previousState,
                                            meterIdError:'',
                                          }});
              }
            console.error("#HandleMeterId error forEach", errPath);
          })
        }

      }

    } else {

      meterIdStatus = false;
      setState(previousState => {return { ...previousState,
                                meterIdError: [{
                                                message: t("install.assetIdMissing")
                                              }]
                                }});

      console.error('meterId saknas');
    }

    const aid = (assetId) ? assetId:'';

    if(meterIdStatus === false){setLoading(false);}

    return {meterIdStatus, meterIdError, aid};
  }

  //
  // ADRESS
  const HandleAddress = async (aid) => {
    assetId = (aid) ? aid:stateAssetId;
    var addressStatus = false;

    const form = document.forms.installForm;
    const address = form.address.value;
    const zip = form.zip.value;
    const city = form.city.value;
    var deliveryArea = form.deliveryArea.value;
    var meterGridArea = form.meterGridArea.value;
    var longitude = form.longitude.value;
    var latitude = form.latitude.value;

    try {

      if(address && zip && deliveryArea  && meterGridArea && longitude && latitude) {

        const setData = {
                  tenantId: tenantId,
                  address: {address1: address, zip: zip, city: city},
                  deliveryArea: deliveryArea,
                  meterGridArea: meterGridArea,
                  geoLocation: {
                    type: "Point",
                    coordinates:[parseFloat(longitude), parseFloat(latitude)]},
        }

        if(assetId){
          await callAPI({
            resource:'/tenant/'+tenantId+'/asset/'+assetId,
            method: 'patch',
            data: setData
          });
        }
        setState(previousState => {return { ...previousState,
                                    address:address,
                                    zip:zip,
                                    city:city
                                  }});

        addressStatus = true;

      } else {
        // show error
        setState(previousState => {return { ...previousState,
                                    gridAreaError:true,
                                  }});
      }
    } catch (error) {
      addressStatus = false;
    }

    if(addressStatus === false){setLoading(false);}

    return {addressStatus};
  }


  // Use for onchange
  const CheckAddress = async (e) => {
    const form = document.forms.installForm;
    const address = form.address.value;
    const zip = form.zip.value;
    const city = form.city.value;

    var itemsArray = [];
    var status = false;
    var respGeoFind = '';
    var setlongitude = '';
    var setlatitude = '';
    var setdeliveryArea = '';
    var setmeterGridArea = '';

    // empty errror msg
    setState(previousState => {return { ...previousState,
                                gridAreaError:''
                              }});

      // find address
      try {
          respGeoFind = await callAPI({
          resource:'/geo/find',
          method: 'post',
          data: {
                  address:{address1:address, zip:zip, city:city}
                  //address:{address1:address, zip:zip}
                }
          });
      } catch (error) {
        console.error('CheckAddress error', error);
      }

    // find and set to state
    if(respGeoFind.length > 0 )
    {
        // v 2.0
        // findcoordinates
        respGeoFind.forEach((item, i)=> {
          // check geolocation data
          if(item && item.geoLocation && item.address  && item.address.address1) {
            const coordinates = item.geoLocation.coordinates;

            setdeliveryArea = item.deliveryArea;
            setmeterGridArea = item.meterGridArea;
            setlongitude = coordinates[0];
            setlatitude = coordinates[1];
          } else {
            itemsArray.push(item);
          }

        });

        // check for match meterGridArea;
        itemsArray.forEach((item)=> {
            if(setmeterGridArea && setmeterGridArea === item.meterGridArea && setdeliveryArea && setdeliveryArea === item.deliveryArea) {
              status = true;
            } else {
            }
        });

        // set data if match
        if(status) {
          setState(previousState => {return { ...previousState,
                                     gridArea:respGeoFind,
                                     deliveryArea:setdeliveryArea,
                                     meterGridArea:setmeterGridArea,
                                     longitude:setlongitude,
                                     latitude:setlatitude,
                                     gridAreaError:''
                                   }});

           // set to values fields
           form.deliveryArea.value = setdeliveryArea;
           form.meterGridArea.value = setmeterGridArea;
           form.longitude.value = setlongitude;
           form.latitude.value = setlatitude;

        } else {
           // Show error
           // empty values
           form.deliveryArea.value = '';
           form.meterGridArea.value = '';
           form.longitude.value = '';
           form.latitude.value = '';

           setState(previousState => {return { ...previousState,
                                      gridArea:'',
                                      deliveryArea:'',
                                      meterGridArea:'',
                                      longitude:'',
                                      latitude:'',
                                    }});
        }
    }
  }

  //////////////////////////////////////////////////////////////

  const titleDeviceModel = Functions.ModelType({type:state.hpType})+' '+state.hpManufacturer+' '+state.hpModel;

  // DeviceModel
  const HandleDeviceModel = async () => {
    assetId = stateAssetId;
    var deviceModelStatus = true;

    const form = document.forms.installForm;
    const deviceModelId = form.selectedDeviceModelId.value;
    setState(previousState => {return { ...previousState,
                            hpType: form.heatpumpTypeSelector.value,
                            hpManufacturer:form.hpManufacturerSelector.value,
                            hpModel:form.hpModelSelector.value,
                            hpSize:form.sizeSelector.value,
                            hpModelId:deviceModelId,
                            deviceModelId:deviceModelId
    }});

    // update
    if(assetId) {
      try {
      await callAPI({
          resource:'/tenant/'+tenantId+'/asset/'+assetId,
          method: assetMethod,
          data: {
                  tenantId: tenantId,
                  deviceModelId: deviceModelId
                }
        });
      } catch(error) {
        const issues = error.response.data.issues;
        setState(previousState => {return { ...previousState,
                                    deviceModelError:issues
                                  }});
        deviceModelStatus = false;
      }
    }

    if(deviceModelStatus === false){setLoading(false);}

    return {deviceModelStatus, deviceModelId};
  }

  const SearchDevice = (e) =>{
    const s = e.target.value
    setDeviceSearch(s);
  }


  const SetDeviceSearch = (id, type, mfr, model, size, docId) => {
    const form = document.forms.installForm;
    form.selectedDeviceModelId.value = id;

    setState(previousState => {return { ...previousState,
                            hpType: type,
                            hpManufacturer: mfr,
                            hpModel: model,
                            hpSize: size,
                            hpModelId: id,
                            deviceModelId:id,
                            docId:docId
    }});

    if(docId){
      form.selectedDeviceModelDocId.value = docId;
      
      GuideLoad(docId);

    } else {
      form.selectedDeviceModelDocId.value = '';
    }
  }

  const SelectDeviceChange = (event, change) =>{
    const val =  event.target.value;
    console.log(val);
    const form = document.forms.installForm;

    if(change === 'type'){
      setState(previousState => {return { ...previousState,
                              hpType: val, hpManufacturer: '', hpModel: '', hpSize:'', deviceModelId:'', docId:''
      }});

      // empty
      form.selectedDeviceModelId.value = ''
      form.selectedDeviceModelDocId.value = '';
    }

    if(change === 'mfr'){
      setState(previousState => {return { ...previousState,
                              hpManufacturer: val, hpModel: '', hpSize:'', deviceModelId:'', docId:''
      }});
      // empty
      form.selectedDeviceModelId.value = ''
      form.selectedDeviceModelDocId.value = '';
    }

    if(change === 'model'){
      setState(previousState => {return { ...previousState,
                              hpModel: val, hpSize:'', deviceModelId:'', docId:''
      }});

      // empty
      form.selectedDeviceModelId.value = ''
      form.selectedDeviceModelDocId.value = '';
    }

    if(change === 'size'){
      //const valIndex = event.target.selectedIndex;
      const valdata =  event.target.options[event.target.selectedIndex].dataset;
      const docId = (valdata.docid)? valdata.docid:'';
      const form = document.forms.installForm;
      form.selectedDeviceModelId.value = valdata.id;
      form.selectedDeviceModelDocId.value = docId;
      setState(previousState => {return { ...previousState,
                              hpSize: val,
                              deviceModelId:valdata.id,
                              docId:docId
      }});

      GuideLoad(docId);
    }
  }

  // guide
  const GuideLoad = (docId) => {
    // load guide
    if(docId) {
      LoadGuideData('first', docId);
      LoadGuideData('dem', docId);
      LoadGuideData('cable', docId);
      LoadGuideData('inst', docId);
      //LoadGuideData('end', docId);
      //LoadGuideData('drift', docId);
    }
  }

  const LoadGuideData = async (section, docId) =>{
    if(docId && section) {
      const randId = Functions.RandId();
      const fileName = 'data_'+section+'.json';
      const UrlFile  = '/guide/'+docId+'/'+fileName+'?'+randId;
      const data = await Functions.APIpromise(UrlFile, 'GET');
      // Sort by pos
      if(data){data.sort((a, b) => a.pos - b.pos);}
      SetGuidesState(section, data);
    }
  }
 // end guide

  const SetGuidesState = (section, data) => {
    if(section === "first"){
       setGuideFirst(data);
    }

    if(section === "dem"){
      setGuideDem(data);
    }
    if(section === "cable"){
      setGuideCable(data);
    }
    if(section === "inst"){
      setGuideInst(data);
    }
    if(section === "drift"){
      setGuideDrift(data);
    }
    if(section === "end"){
      setGuideEnd(data);
    }
  }

  const DeviceSelectedFields = () => {
    const data = JSON.parse(deviceModelsData);

    var type = [];
    var mfr = [];
    var models = [];

    return(
      <>
        <div className="form-group ">
         <select value={state.hpType} onChange={(e)=>SelectDeviceChange(e,'type')} className="w-100 inst-field" name="heatpumpTypeSelector" id="heatpumpTypeSelector">
          <option value="">- {t("install.type")} - </option>
           {data && data.length &&
             data.map((item, i) => {
               if(type.indexOf(item.type)<0)
               {
                  type.push(item.type);
                  return <option key={i} value={item.type}><ModelType type={item.type}/></option>
                } else {return null}
             })
           }
         </select>
        </div>

        <div className="sel-manufacturer select-deviceModel form-group ">
         <select value={state.hpManufacturer} onChange={(e)=>SelectDeviceChange(e,'mfr')} className="w-100  inst-field" name="hpManufacturerSelector" id="hpManufacturerSelector">
            <option value="">- {t("install.manufacturer")} -</option>
            {data && data.length &&
              data.map((item, i) => {
                if(state.hpType === item.type) {
                    if(mfr.indexOf(item.mfr)<0)
                    {
                      mfr.push(item.mfr);
                      return <option key={i} value={item.mfr}>{item.mfr}</option>
                    } else {return null}
                 } else {return null}
              })
            }
         </select>
        </div>

        <div className="sel-hpmodel select-deviceModel form-group ">
         <select value={state.hpModel} onChange={(e)=>SelectDeviceChange(e,'model')} className="w-100  inst-field" name="hpModelSelector" id="hpModelSelector">
           <option value="">- {t("install.model")} -</option>
           {data && data.length &&
             data.map((item, i) => {
               if(state.hpType === item.type && state.hpManufacturer === item.mfr) {
                 if(models.indexOf(item.model)<0)
                 {
                   models.push(item.model);
                   return <option key={i} value={item.model}>{item.model}</option>
                  } else {return null}
                } else {return null}
             })
           }
           </select>
        </div>

        <div className="sel-hpsize select-deviceModel form-group mt-2">
         <select value={state.hpSize}  onChange={(e)=>SelectDeviceChange(e,'size')} className="w-100 pro-select inst-field select-css " name="sizeSelector" id="sizeSelector" required>
           <option value="">- {t("install.size")} -</option>
           {data && data.length &&
             data.map((item, i) => {
               if(state.hpType === item.type && state.hpManufacturer === item.mfr && state.hpModel === item.model) {
                 return <option key={i} value={item.size} data-docid={item.docId} data-id={item.deviceModelId}>{item.size}</option>
               } else {return null}
             })
           }
         </select>
        </div>
     </>
    )
  }


  //
  // Handle cabel
  const HandleCableConnected = async () => {
    var cableStatus = true;
    var hasEVU = false;
    var hasSgReady = false;

    if(Number(state.cable) === 2) {
      hasEVU = true;
      hasSgReady = true;
    } else if(Number(state.cable) === 1) {
      hasEVU = true;
      hasSgReady = false;
    } else {
      hasEVU = false;
      hasSgReady = false;
    }

    try {
      await callAPI({
        resource:'/tenant/'+tenantId+'/asset/'+assetId,
        method: 'patch',
        data: {
                control:{
                  hasEVU: hasEVU,
                  hasSgReady: hasSgReady,
                }
              }
      });
    } catch(error) {
      cableStatus = false;
      setState(previousState => {return { ...previousState,
                                  cableError:[{message:t("install.techError")}]
                                }});
    }

    return {cableStatus};
  }

  const SelectCableChange = (num) => {
    const val = num; //event.target.value;
    setState(previousState => {return { ...previousState,
                                cable: val,
                                cableError:''
                              }});
  }

  //
  const rebootClient = async (clientId) => {
    setViewLoopSGAdesc(t("install.SGARestarts"));
      await callAPI({
      resource: `/tenant/${tenantId}/iot/command/${clientId}`,
      //resource: `/admin/iot/command/${clientId}`,
      method: 'post',
      data: {
              command: 'reboot'
            }
    });
  }
 
  //
  ////
  const getPresence = async (clientId, t) => {
    var resp = '';
    var connected = false;
    //const startDate = (state.createdDate) ? state.createdDate:Functions.CurrentDate()+'T00:00:00.000Z';
    // start date 30 days ago
    const StartDate = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000);
    const StartYear = StartDate.getFullYear();
    const StartDay = (StartDate.getDate() < 10) ? '0'+StartDate.getDate():StartDate.getDate();
    const StartgetMonth = StartDate.getMonth() + 1;
    const StartMonth = (StartgetMonth < 10) ? '0'+StartgetMonth:StartgetMonth;
    const StartDateVal = `${StartYear}-${StartMonth}-${StartDay}T00:00:00.000Z`;

    try {
      resp = await callAPI({
        resource:'/tenant/'+tenantId+'/asset/'+assetId+'/query/presence',
        method: 'post',
        data: {
                start: StartDateVal,
                aggregation: [
                  "last"
                ],
                fields: [
                  "connected"
                ]
              }
      })
   
    }catch(e){
      setViewLoopSGA('error');
      console.error("getPresence error",e);
    }
    // check connected
    if(resp && resp.result[0] && resp.result[0].values[0]) {
      connected = resp.result[0].values[0].last;
    }

   return connected;
  }

  const HardwareCheckStatus = async (clientId) => {
    var connected = false;
    var harwareStatus = '';
    setViewLoopSGA('post');

    if(assetId && tenantId) {

      let loop = Array(60);
      for(var i=0; i < loop.length; i++) {
        loop[i] = Array(60);
          connected = (connected === false) ? await getPresence(clientId, i):true;

          if(connected === false && i === 3) {
            rebootClient(clientId);
          }

          // break
          if(connected === true) {
           break; 
          }
          await sleep(1000);
      }
    
 
      if(connected) { //if(resp && resp.result){
        setViewLoopSGA('ok');
        harwareStatus = true;
      } else{
        harwareStatus = false;
      }
      return {harwareStatus}
    }
  }

  //
  const HandelClientId = async () => {
    /**
    // clientId
    123456
    012333B4779538B501
    01235B8B3933A9E201
    **/
    assetId = stateAssetId;
    var clientIdStatus = false;
    var clientIdResp = '';
    var respAPI = '';
 

    const form = document.forms.installForm;
    const clientIdVal = form.clientId.value;
    const clientId = clientIdVal.toUpperCase().replace(/\s/g, "");

    setState(previousState => {return { ...previousState,
                                clientId:clientId
                              }
                      });

    try {
      // empty error
      setState(previousState => {return { ...previousState,
                                  clientIdError:''
                                }});

      // Bypass if not change and exists
      if(clientId.length > 0 && dataAsset.clientId === clientId) {
      } else {

        if(assetId) {
          clientIdResp = await callAPI({
            resource:'/tenant/'+tenantId+'/asset/'+assetId+'/client',
            method: 'post',
            data: {
                    tenantId: tenantId,
                    clientId: clientId,
                  }
          });

        } else {
            // check if clientId in use create new asset
            clientIdResp = await callAPI({
              resource:'/tenant/'+tenantId+'/asset/?clientId='+clientId,
              method: 'get',
              data: {
                      tenantId: tenantId,
                    }
            });
            const objResp = (clientIdResp && clientIdResp.objects) ? clientIdResp.objects:'';
            const ifNotExist = (objResp.length > 0) ? false:true;
            //
            // CREATE NEW ASSET /////////////////////////
            // create new if not exist
            if(ifNotExist) {
                clientIdStatus = false;

                // new asset
                respAPI = await callAPI({
                    resource:'/tenant/'+tenantId+'/asset/',
                    method: 'post',
                    data: {
                            tenantId: tenantId,
                            comfortCategory: 'comfort',
                            deviceModelId: state.deviceModelId,
                            meterGridArea: state.meterGridArea,
                            deliveryArea: state.deliveryArea,
                          }
                });

                // set assetid if new
                if(respAPI && respAPI.assetId) {

                  assetId = respAPI.assetId;
                  setAssetId(respAPI.assetId);

                  var newurl = window.location.pathname + '?id='+assetId;
                  window.history.pushState({path:newurl},'',newurl);

                  // check if id is correct
                  clientIdResp = await callAPI({
                    resource:'/tenant/'+tenantId+'/asset/'+assetId+'/client',
                    method: 'post',
                    data: {
                            tenantId: tenantId,
                            clientId: clientId,
                          }
                  });
                }
                // end new asset

            } else {
                clientIdStatus = false;
                // if exist show error
                setState(previousState => {return { ...previousState,
                                            clientIdError:[{message:t("install.busyHardwareId")}]
                                          }});
                
                setClientIdCheck(false);
                window.hideQRcheckResult(); 
            }
        }
      }

      clientIdStatus = true;

    } catch (error) {
      const issues = (error && error.response && error.response.data) ? error.response.data.issues:'';
      setState(previousState => {return { ...previousState,
                                  clientIdError:issues
                                }});
      clientIdStatus = false;
      
      setClientIdCheck(false);
      window.hideQRcheckResult();
      
      console.error("HandelClientId error", error);
    }

    //
    if(clientIdStatus === false){
      setLoading(false);
    }

    // check data connection
    if(clientIdStatus){
      const {harwareStatus} = await HardwareCheckStatus(clientId);
      if(harwareStatus){
        clientIdStatus = true;
        setLoading(false);
      } else if(harwareStatus === false){
        setViewLoopSGA('error');
        setLoading(false);
        clientIdStatus = false;
      }
    }
    return {clientIdStatus, clientIdResp}
  }

  //
  ////
  const HandleHardwireId = (e) => {
    e.target.value = e.target.value.replace(/\s/g, "");
    const val = e.target.value;

    if(val.length === 18){
      setClientIdCheck(true);
      window.showQRcheckResult();
    } else {
      setClientIdCheck(false);
      window.hideQRcheckResult();
    }
    setState(previousState => {return { ...previousState,
                                clientIdError:''
                              }});
  }

  const HandleQRClickHardware = () => {
    setState(previousState => {return { ...previousState,
      clientIdError:''
    }});

    setClientIdCheck(false);
    window.hideQRcheckResult();

    window.startQRScan('start','hardware','QRreader');
  }

  const ChangeClientId = () => {
    const form = document.forms.installForm;
    form.clientId.value = form.clientIdCopy.value;
    // toggle
    if(state.clientIdChange) {
      setState(previousState => {return { ...previousState,
                                  clientIdChange: false,
                                  clientId:dataAsset.clientId
                                }});

    } else {
      setState(previousState => {return { ...previousState,
                                  clientIdChange: true,
                                  clientId:dataAsset.clientId
                                }});
    }

    setState(previousState => {return { ...previousState,
      clientIdError:''
    }});

    setClientIdCheck(false);
    window.hideQRcheckResult();
  }

  

  //
  // SENSOR
  // check status of SG-T
  const getTempsensorStatus = async (sensorId, i) => {
    var tempSensorResp = '';
    var tempsensorStatus = false;
    var temperature = '';

    //const StartDate = new Date(Date.now() - 1 * 24 * 60 * 60 * 1000);
    const StartDate = new Date();
    const StartYear = StartDate.getFullYear();
    const StartDay = (StartDate.getDate() < 10) ? '0'+StartDate.getDate():StartDate.getDate();
    const StartgetMonth = StartDate.getMonth() + 1;
    const StartMonth = (StartgetMonth < 10) ? '0'+StartgetMonth:StartgetMonth;
    const StartDateVal = `${StartYear}-${StartMonth}-${StartDay}T00:00:00.000Z`;


    if(tenantId && assetId){
      SendMetrics(tenantId);
      await sleep(1000);

      try {  
          
          tempSensorResp = await callAPI({
            resource: `/tenant/${tenantId}/asset/${assetId}/query/tempSensor`,
            method:'post',
            data: {
                      //scanDays:1,
                      start: StartDateVal,
                      aggregation: ['last'],
                      fields: [
                          "temperature"
                      ]
                  }
          });
           
          temperature = (tempSensorResp && 
                              tempSensorResp.result && tempSensorResp.result.length > 0 &&
                              tempSensorResp.result[0].values[0].last.temperature) ? tempSensorResp.result[0].values[0].last.temperature:'';

          if(temperature !== ''){setTempSensor(temperature);}

        } catch(e) {
          setViewLoopSGT('error');
          setLoading(false);
          console.error("getTempsensorStatus error ", i ,e);
        }
    }

    if(temperature && temperature !== '' && 
        tempSensorResp && 
          tempSensorResp.result && tempSensorResp.result.length > 0){
      tempsensorStatus = true;
    }
    return tempsensorStatus;
  }
  
  const SendMetrics = async (tenantId) => {
    const form = document.forms.installForm;
    const clientIdVal = form.clientId.value;
    const clientIdForm = clientIdVal.toUpperCase().replace(/\s/g, "");
    const clientId = (clientIdForm) ? clientIdForm:state.clientId;

    try {
       await callAPI({
        resource: `/tenant/${tenantId}/iot/command/${clientId}`,
        method: 'post',
        data: {
                command: 'sendMetrics'
              }
      });
    }catch(e){
      console.error("SendMetrics error", e.response.data);
    }
  }

  // 
  const CheckTempsensor = async () => {
    setViewLoopSGT('post');

    var tempsensorStatus = false;
    const clientIdState = state.clientId;

    const form = document.forms.installForm;
    const clientIdVal = form.clientId.value;
    const clientIdForm = clientIdVal.toUpperCase().replace(/\s/g, "");

    const clientId = (clientIdForm) ? clientIdForm:clientIdState;
    if(tenantId && clientId && assetId ){
        try {
          // change this to normal later, use admin temporary
          // command values:  reboot, renewConfig, renewSchedule, sendMetrics
          
          //
            await callAPI({
            resource: `/tenant/${tenantId}/iot/command/${clientId}`,
            method: 'post',
            data: {
                    command: 'renewConfig'
                  }
          });

        }catch(e) {
          setViewLoopSGT('error');
          setLoading(false);
          console.error("CheckTempsensor error", e.response.data);
        }

        // loop tempsensor
        let loop = Array(30);
        for(var i=0; i < loop.length; i++) {
            loop[i] = Array(30);
            setViewLoopSGTcount(i);
            tempsensorStatus = (tempsensorStatus === false) ? await getTempsensorStatus(clientId, i) : true;
            // break
            if(tempsensorStatus === true) {
              break; 
            }
        };

    }

    // check if tempsensor have data
    if(tempsensorStatus) {
      setViewLoopSGT('ok');
    } else {
      setViewLoopSGT('error');
      setLoading(false);
    }
   
    return {tempsensorStatus};
  }

  //
  const HandleSensor = async () => {
    assetId = stateAssetId;
    var sensorIdStatus = true;
    var sensorIdResp = '';

    const form = document.forms.installForm;
    const sensorIdVal = form.sensorId.value;
    const sensorId = sensorIdVal.toUpperCase().replace(/\s/g, "");

    setState(previousState => {return { ...previousState,
                                sensorId:sensorId,
                                sensorInAction:true
                              }
                      });
    try {
      sensorIdResp = await callAPI({
        resource:'/tenant/'+tenantId+'/asset/'+assetId+'/sensor',
        method: 'post',
        data: {
                tenantId: tenantId,
                sensorId: sensorId
              }
      });

      setState(previousState => {return { ...previousState,
                                  sensorIdViewError:false,
                                  sensorIdAddStatus:true,
                                  sensorIdError:'',
                                  sensorInAction:false
                                }});

    } catch (error) {
      const issues = (error && error.response && error.response.data) ? error.response.data.issues:'';
      setState(previousState => {return { ...previousState,
                                  sensorIdError:issues,
                                  sensorIdViewError:false,
                                  sensorInAction:false
                                }});
      sensorIdStatus = false;
      setSensorIdCheck(false);
      window.hideQRcheckSensor();

      console.error("HandelsensorId error", error);
    }

    reloadAsset();
    UpdateDataSensors();

    return {sensorIdStatus, sensorIdResp}
  }


  const UpdateDataSensors = async () =>{
    const data = await callAPI({
      resource: `/tenant/${tenantId}/asset/${assetId}`,
      method: 'get',
    });

    const sensors = (data && data.sensors) ? data.sensors:'';
    setState(previousState => {return { ...previousState,
                                sensorsdata:sensors,
                              }});
  }

  const DeleteSensor = async (id) => {
    try {
      await callAPI({
        resource:'/tenant/'+tenantId+'/asset/'+assetId+'/sensor/'+id,
        method: 'delete'
      });

    } catch(error){
      console.error('DELETE sensor error', error);
    }

    reloadAsset();
    UpdateDataSensors();
    setTempSensor('');

    // Empty warnings
    setState(previousState => {return { ...previousState,
                              sensorIdError:'',
                              }});
  }

  const OnChangeHardwireSensor = (e) => {
    // remove white space
    e.target.value = e.target.value.replace(/\s/g, "");
    const val = e.target.value;

    if(val.length === 12){
      setSensorIdCheck(true);
      window.showQRcheckSensor();
    } else {
      setSensorIdCheck(false);
      window.hideQRcheckSensor();
    }

    setState(previousState => {return { ...previousState,
                                sensorIdError:'',
                                sensorIdViewError:false,
                              }});
  }
  const HandleQRClickHardwareSensor = () => {
    setState(previousState => {return { ...previousState,
      sensorIdError:'',
      sensorIdViewError:false,
    }});
    setSensorIdCheck(false);
    window.hideQRcheckSensor();
    window.startQRScan('start','sensor', 'QRreaderSensor');
  }

  /*
  const ListSensorsX = () => {
    const sensors = (state.sensorsdata) ? state.sensorsdata:dataAsset.sensors;
    if(sensors.length > 0) {
      return (
        <div className="p-2 mt-3">
          <strong>Sensor(er)</strong>
           {
             sensors.map((item, i) => (
                 <div className="p-3 border mb-1 d-flex" key={i}>
                   <div className="bold text-primary"><i className="fa fa-rss" aria-hidden="true"></i> {item.sensorId} {item.model}</div>
                   <div className="btn btn-basic ml-auto" onClick={()=>DeleteSensor(item.sensorId)}>Ta bort</div>
                 </div>
             ))
           }
        </div>
      )
    } else {
      return null;
    }
  }
 */

  //
  // verified
  const HandleVerified = async () => {
    var verifiedResp = '';
    var verifiedStatus = '';
    const form = document.forms.installForm;
    const verifiedCheckBox = form.verified.checked;
    const setverifiedCheckBox = (Number(verifiedCheckBox) === 1) ? true:false;

    setState(previousState => {return { ...previousState,
                                verifiedinActionError:false,
                                verifiedinAction:true,
                              }});

    try {
      if(setverifiedCheckBox) {
        verifiedResp = await callAPI({
          resource:'/tenant/'+tenantId+'/asset/'+assetId,
          method: 'patch',
          data: {
                  tenantId: tenantId,
                  verified: setverifiedCheckBox,
                  isControlEnabled: setverifiedCheckBox
                }
        });
      }
      verifiedStatus = (!verifiedResp.length) ? true:false;
    } catch(error){
      verifiedStatus = false;
      console.error('verified error', error);
    }

    // handle error
    if(verifiedStatus && setverifiedCheckBox){
      setState(previousState => {return { ...previousState,
                                  verifiedinAction:false,
                                  verifiedinActionFinnish:true
                                }});

    } else {

      setState(previousState => {return { ...previousState,
                                verifiedinAction:false,
                                verifiedinActionError:true,
                                verifiedinActionFinnish:false
                              }});

    }

    return {verifiedStatus, verifiedResp, setverifiedCheckBox};
  }

 const HandleCheckboxVerified = () => {

   setState(previousState => {return { ...previousState,
                             verifiedinAction:false,
                             verifiedinActionError:false,
                             verifiedinActionFinnish:false
                           }});
 }

  //
  // Btn actions
  const ActionNextStep = async (set) => {

    const assetId = (stateAssetId) ? stateAssetId:'';

    setLoading(true);

    const form = document.forms.installForm;
    var goNext= false;

    setState(previousState => {return { ...previousState,
                                viewInfo:'',
                                sensorIdError:'',
                                cableError:'',
                                clientIdError:'',

    }});

    // reset guide
    setStepGuide(0);

    //
    // Actions
    if(set === 'next') {
      goNext = true;
      setLoading(false);
      setViewLoopSGA('');
    }

    if(set === 'meterId') {
        // empty error
      setState(previousState => {return { ...previousState,
                                meterIdError:'',
                                gridAreaError:''
                                }});

        const {meterIdStatus, aid} = await HandleMeterId();
        const {addressStatus} = await HandleAddress(aid);


        if(meterIdStatus && addressStatus){
          goNext = true;
        } else {
          goNext = false;
          setLoading(false);
        }
    }

    if(set === 'deviceModel') {
      const deviceModelId = form.selectedDeviceModelId.value;
      const size = form.sizeSelector.value;
      if(size.length > 0 && deviceModelId.length > 0){
        const {deviceModelStatus} = await HandleDeviceModel();
        goNext = (deviceModelStatus) ? true:false;
      } else {
        setLoading(false);
        goNext = false;
      }
    }

    if(set === 'clientId') {
      if(form.clientId.value){
        
        await HandelClientId();
        //const {clientIdStatus} = await HandelClientId();
        //use loop btn view OK instead
        //goNext = (clientIdStatus) ? true:false;
      } else {
        setLoading(false);
      }
    }

    if(set === 'cable'){
      const {cableStatus} = await HandleCableConnected();
      goNext = (cableStatus) ? true:false;
    }

    if(set === 'sensor') {
      var sensorCheckStatus = false;

        // check sensorid to go next ///////
        const sensorIdVal = form.sensorId.value;
        const sensorId = sensorIdVal.toUpperCase().replace(/\s/g, "");
        
        if(state.sensorsdata && state.sensorsdata.length === 0) {

          if(sensorId) {
            const sensorResp = await HandleSensor();
            const sensorStatus = (sensorResp && sensorResp.sensorIdStatus) ? true:false;
    
            if(sensorStatus) {
              setState(previousState => {return { ...previousState,
                sensorIdAddStatus:true,
                sensorIdError:'',
                sensorIdViewError:false,
              }});
  
              sensorCheckStatus = true;
            } else {  
               setLoading(false);
            }
          } else {
            setState(previousState => {return { ...previousState,
              sensorIdAddStatus:true,
              sensorIdError:'',
              sensorIdViewError:true,
            }});
  
            setLoading(false);
          }  

        } else {
          // empty fields
          setState(previousState => {return { ...previousState,
            sensorIdAddStatus:'',
            sensorIdError:'',
            sensorIdViewError:''
          }});
          form.sensorId.value = '';
          sensorCheckStatus = true;
        }

        if(sensorCheckStatus){
          setViewLoopSGT('post');
          // check paring 
          CheckTempsensor();
        }
       // check sensorid to go next ///////
    }

    if(set === 'skipsensor') {
      form.sensorId.value = '';
      setState(previousState => {return { ...previousState,
                                  sensorIdAddStatus:true,
                                  sensorIdError:'',
                                  sensorIdViewError:false,
                                }});
      goNext = true;
    }

    //
    // actions
    if(assetId){
      reloadAsset();
    }

    // View next section
    if(goNext) {
      const s = parseInt(stateViewStep) + 1;
      setViewStep(s);

      // This will create a new entry in the browser's history, without reloading
      if(assetId) {
        var nextURL = '?id='+assetId+'&step='+s;
        window.history.pushState('', '', nextURL);
      }

      ScrollToTop();
      setLoading(false);

      // update before load
      /*
      if(Number(s) === 8){
        UpdateDataSensors();
      }
      */
    }
  }

  //
  //
  const ViewBackStep = () => {
    setViewLoopSGA('');
    setViewLoopSGT('');
    setLoading(false);

    if(Number(stateStepGuide) > 0){
      const countUp = parseInt(stateStepGuide - 1);
      setStepGuide(countUp);
    } else {
      setState(previousState => {return { ...previousState,
                                viewInfo:''
                                }
                        });

      var s = parseInt(stateViewStep) - 1;
      s = (s<=1) ? 1:s;
      setViewStep(s);

      // This will create a new entry in the browser's history, without reloading
      var nextURL = '?id='+assetId+'&step='+s;
      window.history.pushState('', '', nextURL);
    }

    ScrollToTop();
  }

  const BtnNext = (set, text) => {
    //  <i className="fas fa-angle-right text-4"></i>
    const info = (text) ? text:btnNextTitle;
    const setFunc = (set) ? set:'';
    return <div onClick={()=>ActionNextStep(setFunc)} className="btn btn-primary bold btn-block p-3 mt-3"> {info}  {stateLoading && <span className="spinner-border spinner-border-sm"></span>}</div>
  }

  const stepBackBtn = (<div><span onClick={()=>ViewBackStep()} className="pointer"> <i className="fa fa-angle-left"></i> {t("install.back")}</span></div>);

  const Nav = (title) => {
    return (
      <>
        <div className="top-nav" ref={topbody}>
          <div className="flex mx-auto w-50 auto-100 relative">
             <div className="w-15 no-wrap pt-1">
                <div  className={Number(stateViewStep) === 1 ? 'hidden':'show text-left'}>{stepBackBtn}</div>
                <div  className={Number(stateViewStep) === 1 ? 'show text-left':'hidden'} aria-hidden="true" data-toggle="modal" data-target="#exitModal"><i className="fa fa-angle-left"></i> {t("install.cancel")}</div>
              </div>
             <div className="w-70 bold text-center"><h5 className="bold">{title}</h5></div>
             <div className="w-15 text-right bold pr-2 pt-1">{stateViewStep}/10</div>
          </div>
        </div>
        
        <div className="text-center mt-5 pt-2">
           
        </div>
      </>
    );
  };

  return(
    <>
       <form onSubmit={HandleSubmitX} className="p-0 w-60 mx-auto auto-100 relative" id="installForm" name="installForm">
     

         <div className={!stateViewStep || Number(stateViewStep) === 1 ? 'show':'hidden'}>
           {assetId ? Nav(t("install.asset")):Nav(t("install.createAsset"))}
           
              <CardBox2 title={assetId ? t("install.asset"):t("install.createAsset")} content={
               <div>
                  <div className="content-box">
                      <div>
                        <label className="content-title">1. {t("install.assetIdEan")}</label>
                      </div>
                      <span dangerouslySetInnerHTML={{__html: t("install.writeAssetId")}}></span>

                      <div className="mt-2">
                      <input type="number" defaultValue="7" maxLength="1" className="text-center bold input-box disabled" disabled/>
                      <input type="number" defaultValue="3" maxLength="1" className="text-center  bold input-box disabled" disabled />
                      <input type="number" defaultValue="5" maxLength="1" className="text-center bold input-box disabled" disabled />
                      <input type="number" defaultValue="9" maxLength="1" className="text-center bold input-box disabled" disabled />
                      <input type="number" defaultValue="9" maxLength="1" className="text-center  bold input-box disabled" disabled />
                      <input type="number" defaultValue="9" maxLength="1" className="text-center bold input-box disabled" disabled />
                      </div>

                      <div className="no-wrap">
                      <input type="number" pattern="[0-9]*" placeholder="_" defaultValue={MeterIdValue(6,7)} name="m1" id="m1" maxLength="1" className="text-center bold input-box " onKeyDown={(e)=>{e.target.value=''}} onKeyUp={(e) => {MeterIdKeyUp(e)}} required />
                      <input type="number" pattern="[0-9]*" placeholder="_" defaultValue={MeterIdValue(7,8)} name="m2" id="m2" maxLength="1" className="text-center bold input-box " onKeyDown={(e)=>{e.target.value=''}} onKeyUp={(e) => {MeterIdKeyUp(e)}} required />
                      <input type="number" pattern="[0-9]*" placeholder="_" defaultValue={MeterIdValue(8,9)} name="m3" id="m3" maxLength="1" className="text-center bold input-box " onKeyDown={(e)=>{e.target.value=''}} onKeyUp={(e) => {MeterIdKeyUp(e)}} required />
                      <input type="number" pattern="[0-9]*" placeholder="_" defaultValue={MeterIdValue(9,10)} name="m4" id="m4" maxLength="1" className="text-center bold input-box " onKeyDown={(e)=>{e.target.value=''}} onKeyUp={(e) => {MeterIdKeyUp(e)}} required />
                      <input type="number" pattern="[0-9]*" placeholder="_" defaultValue={MeterIdValue(10,11)} name="m5" id="m5" maxLength="1" className="text-center bold input-box " onKeyDown={(e)=>{e.target.value=''}} onKeyUp={(e) => {MeterIdKeyUp(e)}} required />
                      <input type="number" pattern="[0-9]*" placeholder="_" defaultValue={MeterIdValue(11,12)} name="m6" id="m6" maxLength="1" className="text-center bold input-box " onKeyDown={(e)=>{e.target.value=''}} onKeyUp={(e) => {MeterIdKeyUp(e)}} required />
                      <input type="number" pattern="[0-9]*" placeholder="_" defaultValue={MeterIdValue(12,13)} name="m7" id="m7" maxLength="1" className="text-center bold input-box " onKeyDown={(e)=>{e.target.value=''}} onKeyUp={(e) => {MeterIdKeyUp(e)}} required />
                      <input type="number" pattern="[0-9]*" placeholder="_" defaultValue={MeterIdValue(13,14)} name="m8" id="m8" maxLength="1" className="text-center bold input-box " onKeyDown={(e)=>{e.target.value=''}} onKeyUp={(e) => {MeterIdKeyUp(e)}} required />
                      <input type="number" pattern="[0-9]*" placeholder="_" defaultValue={MeterIdValue(14,15)} name="m9" id="m9" maxLength="1" className="text-center bold input-box " onKeyDown={(e)=>{e.target.value=''}} onKeyUp={(e) => {MeterIdKeyUp(e)}} required />
                      <input type="number" pattern="[0-9]*" placeholder="_" defaultValue={MeterIdValue(15,16)} name="m10" id="m10" maxLength="1" className="text-center bold input-box " onKeyDown={(e)=>{e.target.value=''}} onKeyUp={(e) => {MeterIdKeyUp(e)}} required />
                      <input type="number" pattern="[0-9]*" placeholder="_" defaultValue={MeterIdValue(16,17)} name="m11" id="m11" maxLength="1" className="text-center bold input-box " onKeyDown={(e)=>{e.target.value=''}} onKeyUp={(e) => {MeterIdKeyUp(e)}} required />
                      <input type="number" pattern="[0-9]*" placeholder="_" defaultValue={MeterIdValue(17,18)} name="m12" id="m12" maxLength="1" className="text-center bold input-box " onKeyDown={(e)=>{e.target.value=''}} onKeyUp={(e) => {MeterIdKeyUp(e)}} required />
                     </div>

                      {state.meterIdError.length > 0 &&
                        <div className="text-danger p-1">
                        <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> <strong>{t("install.invalidAssetInput")}</strong>
                         {
                           state.meterIdError.map((err, i) => (
                               <div key={i}>{err.message}</div>
                           ))
                         }
                        </div>
                      }
                  </div>

                  <div className="mt-3 content-box">
                    <label className="content-title">2. {t("install.enterAddress")}</label><br/>
                    <div className="form-group relative">
                      <div><input onChange={(e)=>CheckAddress(e)} defaultValue={
                        state.address ? (
                          state.address
                        ):(
                          dataAsset.address && dataAsset.address.address1 && dataAsset.address.address1
                       )
                      } name="address" id="address"  type="text"  className=" inst-field" placeholder={t("install.streetAddress")}/></div>
                      <div className="d-flex mt-3">
                       <div className="w-40 mr-3"><input onChange={(e)=>CheckAddress(e)} defaultValue={state.zip ? (state.zip):(dataAsset.address && dataAsset.address.zip && dataAsset.address.zip)} name="zip" id="zip"  type="number" maxLength={5}   pattern="[0-9]*" className=" inst-field" placeholder={t("install.postalCode")}/></div>
                       <div className="w-60"><input onChange={(e)=>CheckAddress(e)} defaultValue= {state.city ? (state.city):(dataAsset.address && dataAsset.address.city && dataAsset.address.city)} name="city" id="city"  type="text"  className=" inst-field" placeholder={t("install.city")}/></div>
                      </div>

                      <input className="hidden" type="text" defaultValue={state.deliveryArea} name="deliveryArea" id="deliveryArea" />
                      <input className="hidden" type="text" defaultValue={state.meterGridArea} name="meterGridArea" id="meterGridArea" />
                      <input className="hidden" type="text" defaultValue={state.longitude} name="longitude" id="longitude" placeholder="longitude"/>
                      <input className="hidden" type="text" defaultValue={state.latitude} name="latitude" id="latitude" latitude="latitude" placeholder="latitude"/>

                    </div>

                    {state.gridAreaError ? (
                        <div className="text-danger p-2">
                         <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> <strong dangerouslySetInnerHTML={t("install.areaNotFound")}/>
                        </div>
                      ) : (
                        <>
                         {state.deliveryArea && state.meterGridArea && state.longitude && state.latitude &&
                           <div className="mt-3 p-2 text-center">
                            <i className="fa fa-plug text-big " aria-hidden="true"></i> {t("install.electricityArea")}<br/>
                            <strong className="text-success text-big ">{state.deliveryArea} {state.meterGridArea}</strong>

                            <div className="mt-3">
                             <div className="text-sm">{t("install.mapOverlay")}</div>
                              <iframe title="google-map" className="resp-iframe" src={"https://maps.google.com/maps?q="+state.latitude+",%20"+state.longitude+"&t=satellite&t=&z=14&ie=UTF8&iwloc=&output=embed"} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture full" allowFullScreen></iframe>
                            </div>

                           </div>
                         }
                        </>
                      )
                    }

                  </div>

                  {state.viewInfo}
                  {BtnNext('meterId')}
               </div>
              }/>
          </div>


          <div className={Number(stateViewStep) === 2 ? 'show':'hidden'}>
            {Nav(t("install.selectHeatPump"))}
             <CardBox2 title="Välj värmepump" content={
               <>
                   <div className="content-box">
                      <div className="form-group pb-3">
                         <div className="text-right pr-1 text-link pointer "  aria-hidden="true" data-toggle="modal" data-target="#searchHPModal"><i className="fa fa-search "></i> {t("install.search")}</div>
                      </div>

                      <div className="relative mt-2">
                         {DeviceSelectedFields()}
                         <input className="w-100 hidden" type="text" defaultValue={state.deviceModelId} name="selectedDeviceModelId" id="selectedDeviceModelId"/>
                         <input className="w-100 hidden" type="text" defaultValue={state.docId} name="selectedDeviceModelDocId" id="selectedDeviceModelDocId"/>

                      </div>
                      {state.viewInfo}
                   </div>

                  <div desc="SEARCH" className="modal no-border fade mt-6" id="searchHPModal" tabIndex="-1" role="dialog" aria-labelledby="searchHPModal"
                        aria-hidden="true">
                        <div className="modal-dialog no-border" role="document">
                            <div className="modal-content  content-box">
                                <div className="modal-header no-border">
                                    <span className="close pointer" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true" className="text-white">×</span>
                                    </span>
                                </div>

                                <div className="p-3">
                                  <div className={"search-obj relative"}>
                                    <div className="fieldicon"><i className="fa fa-search text-sm action-hp-search pointer" aria-hidden="true"></i></div>
                                    <input onChange={(e)=>SearchDevice(e)} defaultValue={stateDeviceSearch} className="form-control p-3 text-center bold" name="hpsearchfieldX" id="hpsearchfieldX" type="text" placeholder={t("install.searchHeatPumpModel")} />
                                  </div>

                                  <DeviceSearch callback={SetDeviceSearch} search={stateDeviceSearch}/>
                                </div>

                            </div>
                        </div>
                  </div>

                  {state.deviceModelError.length > 0 &&
                      <div className="text-danger p-1">
                      <strong>{t("install.errorOccurred")}</strong>
                       {
                         state.deviceModelError.map((err, i) => (
                             <div key={i}>{err.message}</div>
                         ))
                       }
                      </div>
                  }
                  {BtnNext('deviceModel')}
               </>
             }/>
          </div>

 
          <div className={Number(stateViewStep) === 3 ? 'show':'hidden'}>
            {Nav(t("install.prepareInstallation"))}
             <CardBox2 title={t("install.prepareInstallation")} content={
               <>
                  {GuideInfo.Hardware1(t)}
                  {BtnNext('next')}
               </>
             }/>
          </div>
        
    
          <div className={Number(stateViewStep) === 4 ? 'show':'hidden'}>
            {Nav(t("install.test4GCoverage"))}
             <CardBox2 title={t("install.test4GCoverage")} content={
               <>
                  {GuideInfo.Hardware2(t)}
                  {BtnNext('next')}
               </>
             }/>
          </div> 
                
       
          <div className={Number(stateViewStep) === 5 ? 'show relative':'hidden'}>   
      

            {Nav(t("install.guide"), 'guide')}
             <CardBox2 title={t("install.guide")} content={
               <div className="mt-2 relative">                 

                  {GuideInfo.DeviceGuide(state.docId,
                                            { state:state,
                                              titleDeviceModel:titleDeviceModel,
                                              BtnNext:BtnNext,
                                              stateStepGuide:stateStepGuide,
                                              setStepGuide:setStepGuide,
                                              stateGuideFirst:stateGuideFirst,
                                              stateGuideDem:stateGuideDem,
                                              stateGuideCable:stateGuideCable,
                                              stateGuideInst:stateGuideInst,
                                              stateGuideDrift:stateGuideDrift,
                                              stateGuideEnd:stateGuideEnd,
                                              ScrollToTop:ScrollToTop,
                                              setWarningModal:setWarningModal,
                                              WarningModal:WarningModal,
                                              t // Passing 't' for translation flexibility, even though it currently ties the shared 'DeviceGuide' function to its caller's translation context.
                                              //  Prioritizing simplicity over immediate reusability, as the function's usage is limited and the app is expected to be rewritten soon.
                                            } )}
                 {state.docId &&
                    <div onClick={()=>ActionNextStep('next')} className="mt-2 w-100 text-link text-center">
                      {t("install.skipGuide")}
                    </div>
                 }
               </div>
             }/> 
          </div>           
          
          <div className={Number(stateViewStep) === 6 ? 'show':'hidden'}>
            {Nav(t("install.confirmExternalInputs"))}
               <CardBox2 title={t("install.confirmExternalInputs")} content={
                 <>
                   <div className="content-box">
                        <div className="mt-2 content-title text-center">
                           {t("install.howManyExternalInputs")}</div>
                        <div>

                        <div className={state.cable === 0 ? 'btn btn-select w-100 flex p-2 mt-3':'btn border-dark w-100 flex p-2 mt-3'}  onClick={()=>SelectCableChange(0)}>
                            <div className="w-50 text-right"><h4><strong>0</strong></h4></div>
                            <div className="w-50 text-right pr-2"><h4><i className={state.cable === 0 ? "fa fa-check":""}></i></h4></div>
                        </div>
                        <div className={state.cable === 1 ? 'btn btn-select w-100 flex p-2 mt-3':'btn border-dark  w-100 flex p-2 mt-3'}  onClick={()=>SelectCableChange(1)}>
                          <div className="w-50 text-right"><h4><strong>1</strong></h4></div>
                          <div className="w-50 text-right pr-2"><h4><i className={state.cable === 1? "fa fa-check":""}></i></h4></div>
                        </div>
                        <div className={state.cable === 2 ? 'btn btn-select w-100 flex p-2 mt-3':'btn border-dark  w-100 flex p-2 mt-3'}  onClick={()=>SelectCableChange(2)}>
                          <div className="w-50 text-right"><h4><strong>2</strong></h4></div>
                          <div className="w-50 text-right pr-2"><h4><i className={state.cable === 2 ? "fa fa-check":""}></i></h4></div>
                        </div>

                        {state.cableError && state.cableError.length > 0 &&
                          <div className="text-danger p-2">
                          <strong>{t("install.errorOccurred")}</strong>
                           {
                             state.cableError.map((err, i) => (
                                 <div key={i}>{err.message}</div>
                             ))
                           }
                          </div>
                        }

                        <div className="mt-2 bg-light">
                          <img src={"/img/Kablagebeskrivning_"+state.cable+".png?2"} className="mt-3" alt="sa-hole" title="sa-hole" width="100%" />
                        </div>


                      </div>
                    </div>

                    <div className="mt-3 text-center text-grey">
                      <strong><i className="fa fa-info-circle"></i> </strong><span dangerouslySetInnerHTML={{__html: t("install.externalInputNote")}}/>
                    </div>

                    {BtnNext('cable')}
                 </>
               }/>
          </div>



          <div className={Number(stateViewStep) === 7 ? 'show':'hidden'}>
            {Nav(t("install.hardwareSGA"))}
             <CardBox2 title={t("install.connectHardware")} content={
               <>

                  <div id="QRreader" className="w-100 text-center QRreader">
                  </div>
                 
                  <div className="content-box">
                    <div className="flex">                        
                        <div className="pr-2"><img src="/img/SG-A-2.png" alt="SG-A-2.png" width="70"></img></div>
                        <div className="">
                          <strong>{t("install.scanQRCodeToConnect")}</strong><br/>
                          <i className="text-grey">{t("install.enterID")}</i>
                        </div>                        
                    </div>
                    <div className="flex mt-3">
                      <div className="relative w-100">
                        <input type="hidden" name="clientIdCopy" id="clientIdCopy" defaultValue={state.clientId ? (state.clientId):(dataAsset.clientId)} />
                        <input disabled={state.clientIdChange === false && dataAsset.clientId ? 'disabled':''} defaultValue={state.clientId ? state.clientId:dataAsset.clientId} onChange={(e)=>HandleHardwireId(e)} name="clientId" id="clientId"  type="text" className=" no-border  inst-field" placeholder={t("install.enterHardwareID")} required/>
                        <div className="qricon pointer p-0">
                          <div className="flex p-0 relative">
                            <div id="ClientIdCheck" className={stateClientIdCheck ? "show pt-2 pr-2":"hidden pt-2 pr-2"}><i className="fa fa-check text-big"></i></div>                            
                            <div onClick={()=>ChangeClientId()} className={dataAsset.clientId && state.clientIdChange === false ? 'show':'hidden'}><div className="text-link p-2">{t("install.change")}</div></div>
                            <div onClick={()=>ChangeClientId()} className={state.clientIdChange === true && dataAsset.clientId ? 'show':'hidden'}><div className="text-link p-2">{t("install.undo")}</div></div>
                            <div onClick={() => HandleQRClickHardware()} className={state.clientIdChange === false && dataAsset.clientId ? 'hidden btn p-1':'show ml-1 btn p-1'} style={{backgroundColor:'#FFF'}}>
                              <img src="/comp/qr/qr-icon.png" alt="QR" className="pointer text-white" width="28" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {state.clientIdError && state.clientIdError.length > 0 &&
                        <div className="text-danger p-2">
                        <strong>{t("install.errorOcurred")}</strong>
                          {
                            state.clientIdError.map((err, i) => (
                                <div key={i}>{err.message}</div>
                            ))
                          }
                        </div>
                      }
                  </div> 

                  <div className="modal p-2" style={{display: stateViewLoopSGA !== '' ? 'block':'none'}}>
                    <div className="modal-dialog" style={{marginTop:"4em"}}> 
                        <div className='content-box bg-info modal-content text-center  pt-3 pb-3' >
                        <div className=""><img src="/img/SG-A.png" alt="SG-T" width="140"></img></div>
                     
                            <div className="text-center mt-4 pb-5" style={{display: stateViewLoopSGA === 'post' ? 'block':'none'}}>
                              <div class="spinner-border text-light"></div>
                              <div className="mt-2  text-big bold" dangerouslySetInnerHTML={{__html: t("install.connectingSGA")}}/>
                              <div>{stateViewLoopSGAdesc}</div>
                              <div onClick={()=>setViewLoopSGA('')} className="mt-3 btn  w-100 hidden">{t("install.cancel")}</div>
                            </div>
                            

                            <div className="text-center mt-4 pb-5" style={{display: stateViewLoopSGA === 'ok' ? 'block':'none'}}>
                              <i className="fa fa-check" style={{fontSize:'3em'}}></i>
                              <div className="text-big bold"> {t("install.success")}</div>
                              <div dangerouslySetInnerHTML={{__html: t("install.sgaConnected")}}/>
                              <div onClick={()=>ActionNextStep('next')} className="btn btn-primary bold btn-block p-3 mt-5">{t("install.continue")}</div>
                            </div>

                            <div className="text-center pb-5" style={{display: stateViewLoopSGA === 'error' ? 'block':'none'}}>
                              <i className="fa fa-alert" style={{fontSize:'4em'}}></i>
                              <div className="">
                                  <p className="text-big text-danger bold" dangerouslySetInnerHTML={{__html: t("install.sgaConnectionFailed")}}/>
                                  <div dangerouslySetInnerHTML={{__html: t("install.SGATroubleshoot")}}/>
                              </div>
                              <div onClick={()=>ActionNextStep('clientId')} className="btn btn-primary bold btn-block p-3 mt-3">{t("install.tryAgain")}</div>
                              <div onClick={()=>setViewLoopSGA('')} className="mt-3 btn btn-dark btn-block w-100 ">{t("install.cancel")}</div>

                              <div className="mt-4">
                                <i>
                                  <i className="fa fa-info-circle"></i> <span dangerouslySetInnerHTML={{__html: t("install.multipleFailures")}}/>
                                </i>
                                <div onClick={()=>ActionNextStep('next')} className="btn btn-block mt-1"><u>{t("install.continueWithInstallation")} <i className="fa fa-angle-right"></i></u></div>
                              </div>

                            </div>
                            <br/> <br/> <br/>
                        </div>    
                    </div> 
                  </div>

 
                  <div className="mt-3 p-2 content-info text-grey ">
                      <i className="fa fa-info-circle text-2"></i>&nbsp;
                      <i>
                        {t("install.stepDescription")}
                      </i>
                  </div>

                  
                  {BtnNext('clientId')}

               </>
             }/>
        </div>
 

          <section className={Number(stateViewStep) === 8 ? 'show':'hidden'}>
            {Nav(t("install.tempSensorSGT"))}
               <CardBox2 title={t("install.tempSensorSGT")} content={
                 <>
                   <div id="QRreaderSensor" className="w-100 text-center QRreaderSensor">
                   </div>

                  <div className={state.sensorsdata && state.sensorsdata.length > 0 ? "hidden":"show"}>
                    <div className="content-box">
                          <div className="flex">                        
                              <div className="pr-3"><img src="/img/SG-T.png" alt="SG-T" width="50"></img></div>
                              <div dangerouslySetInnerHTML={{__html: t("install.scanQRCodeToAddSensor")}}/>
                          </div>

                          <div className="relative mt-3">
                            <div id="hardware-check-sensor" className={state.hardwareSensorStatus ? ("hardware-check show fieldicon pr-5"):("hardware-check hidden fieldicon pr-5")}><i className="fa fa-check-circle text-primary" aria-hidden="true"></i></div>
                            <div id="hardware-check-sensor-false" className={state.hardwareSensorStatus ? ("hidden fieldicon pr-5"):("hidden fieldicon pr-5")}><i className="fa fa-times text-danger" aria-hidden="true"></i></div>

                            <input defaultValue={state.sensorId} onChange={(e)=>OnChangeHardwireSensor(e)} name="sensorId" id="sensorId"  type="text" className=" inst-field" placeholder={t("install.enterSensorId")}/>

                            <div className="qricon pointer p-0">
                                <div className="flex p-0 relative">
                                  <div id="SensorIdCheck" className={stateSensorIdCheck ? "show pt-2 pr-3":"hidden pt-2 pr-3"}><i className="fa fa-check text-big"></i></div>
                                  <div onClick={() => HandleQRClickHardwareSensor()} className="btn p-1" style={{backgroundColor:'#FFF'}}>                                  
                                    <img src="/comp/qr/qr-icon.png" alt="QR" className="pointer text-white" width="28" />
                                  </div>
                                </div>
                            </div>

                          </div>

                        {state.sensorIdError && state.sensorIdError.length > 0 &&
                          <div className="text-danger p-2">
                          <strong>{t("install.errorOccurred")}</strong>
                          {
                            state.sensorIdError.map((err, i) => (
                                <div key={i}>{err.message}</div>
                            ))
                          }
                          </div>
                        }

                      
                      <div className="hidden">
                        <div id="addSensorBtn" className={stateSensorIdCheck && state.sensorsdata && state.sensorsdata.length > 0 ? 'show':'hidden'}>
                          <div onClick={()=>HandleSensor()} className="btn btn-info bold  d-flex justify-content-center w-100 text-center mt-3 p-2">
                              <div><i className="fa fa-plus"></i> {t("install.addTempSensor")}</div>
                              <div className={state.sensorInAction ? 'show':'hidden'}><span className="spinner-border spinner-border-sm ml-3"></span></div>
                          </div>
                        </div>
                      </div>


                    </div>
                  </div>

                   {/*<ListSensors tenantId={tenantId} assetId={assetId} deleteSensor={DeleteSensor}/>*/}

                   {/*
                     apiAssetMutation.isSuccess && apiAssetMutation.data && apiAssetMutation.data.sensors.length > 0 &&
                     <div className="p-2 mt-3">
                       <strong>Sensor(er)</strong>
                        {
                          apiAssetMutation.data.sensors.map((item, i) => (
                              <div className="p-3 border mb-1 d-flex" key={i}>
                                <div className="bold text-primary"><i className="fa fa-rss" aria-hidden="true"></i> {item.sensorId} {item.model}</div>
                                <div className="btn btn-basic ml-auto" onClick={()=>DeleteSensor(item.sensorId)}>Ta bort</div>
                              </div>
                          ))
                        }
                     </div>
                   */}

                   {
                     state.sensorsdata && state.sensorsdata.length > 0 &&
                     <div className="p-2 content-box">
                       <strong><i className="fa fa-angle-down"></i> {t("install.tempSensorAdded")}</strong> <br/>
                                      
                        {
                          state.sensorsdata.map((item, i) => {
                              return (
                                <div className="mt-3 d-flex  inst-field" key={i}>
                                   <div className="pr-2"><img src="/img/SG-T.png" alt="SG-T" width="20"></img></div>
                                  <div className="bold text-primary"> {item.sensorId} {item.model}</div>
                                  <div className="pointer ml-auto no-wrap" onClick={()=>setDeleteTempsensorId(item.sensorId)}>
                                    {stateTempSensor}&#8451; <i className="fa fa-trash ml-4 text-grey"  aria-hidden="true" data-toggle="modal" data-target="#deleteTempsensor"></i></div>
                                </div>
                            )}
                          )
                        }
                     
                     </div>
                   }

                  <ModalBox name="deleteTempsensor" content={
                      <div className="mx-auto text-center">
                        
                        <div className="text-2" dangerouslySetInnerHTML={{__html: t("install.confirmDelete", {tempSensorId: stateDeleteTempsensorId})}}/>
                        <div className="d-flex justify-content-center p-4">
                          <div data-dismiss="modal" aria-label="Close">
                            <div onClick={()=>DeleteSensor(stateDeleteTempsensorId)}className="btn btn-danger">{t("install.yes")}</div>
                          </div>
                          <div  className="w-10"></div>
                          <div className="btn btn-light" data-dismiss="modal" aria-label="Close">{t("install.no")}</div>
                        </div>
                      </div>
                    } />


                   <div className={state.sensorIdViewError && state.sensorsdata && state.sensorsdata.length === 0  ? 'show':'hidden'}>
                     <div className=" mt-3 w-100 text-center">
                      <h4 className="text-danger">{t("install.sensorMissing")}</h4>
                      <div onClick={()=>ActionNextStep('skipsensor')} className="btn btn-dark w-100 bold p-3">{t("install.installWithoutSGT")} <i className="fa fa-angle-right"></i> </div>
                     </div>
                   </div>


                   <div className="mt-3 p-2 content-info text-grey hidden">
                      <i className="fa fa-info-circle text-2"></i>&nbsp;
                      <i>
                        {t("install.proceedWithoutSGT")}
                      </i>
                  </div>

                  <div className="modal p-2" style={{display: stateViewLoopSGT !== '' ? 'block':'none'}}>
                    <div className="modal-dialog" style={{marginTop:"4em"}}> 
                        <div className='content-box bg-info modal-content text-center  pt-2 pb-5' >
                            <div className="mt-4" style={{display: (stateViewLoopSGT === 'loop') || (stateViewLoopSGT === 'ok')  || (stateViewLoopSGT === 'error') ? 'block':'none'}}><img src="/img/SG-T.png" alt="SG-T" width="60"></img></div>
                        
                            <div className="text-center pb-5" style={{display: stateViewLoopSGT === 'post' ? 'block':'none'}}>
                              {stateViewLoopSGTcount > 1 ? (<>
                                <h3 className="bold mt-2" dangerouslySetInnerHTML={{__html: t("install.pairTempSensor")}}/>
                                <div className="p-1" style={{backgroundColor:"", borderRadius:"10px"}}>
                                  <div className="mt-1 bold">
                                    <div dangerouslySetInnerHTML={{__html: `1. ${t("install.pairingInstructions1")}`}}/>
                                    <div dangerouslySetInnerHTML={{__html: `2. ${t("install.pairingInstructions2")}`}}/>                                  
                                  </div>
                                  <div className=""><img src="/img/SG-T-push-1.png" alt="SG-T" width="80%" className="border"></img></div>
                                  <div className="mt-4">
                                    <div class="spinner-border text-light"></div>
                                    <div className="mt-2 bold" dangerouslySetInnerHTML={{__html: t("install.connectingSensor")}}/>
                                    <div onClick={()=>setViewLoopSGT('')} className="mt-3 btn  w-100 hidden">{t("install.cancel")}</div>
                                  </div>
                                </div>
                              </>):(<>
                                  <div className="mt-4">
                                    <div class="spinner-border text-light"></div>
                                    <div className="mt-2 bold" dangerouslySetInnerHTML={{__html: t("install.connectingSensor")}}/>
                                    <div onClick={()=>setViewLoopSGT('')} className="mt-3 btn  w-100 hidden">{t("install.cancel")}</div>
                                  </div>
                              </>)}

                            </div>
                            
                            <div className="text-center mt-4 pb-5" style={{display: stateViewLoopSGT === 'ok' ? 'block':'none'}}>
                              <i className="fa fa-check" style={{fontSize:'3em'}}></i>
                              <div className="text-big bold">{t("install.done")}</div>
                              <h2>{stateTempSensor && stateTempSensor+"℃"}</h2>
                              <div dangerouslySetInnerHTML={{__html: t("install.sensorConnected")}}/>
                              <div onClick={()=>ActionNextStep('next')} className="btn btn-primary bold btn-block p-3 mt-5">{t("install.continue")}</div>
                            </div>

                            <div className="text-center mt-4 pb-5" style={{display: stateViewLoopSGT === 'error' ? 'block':'none'}}>
                              <i className="fa fa-alert" style={{fontSize:'4em'}}></i>
                              <div>
                                  <p className="text-big text-danger bold" dangerouslySetInnerHTML={{__html: t("install.pairingFailed")}}/>
                                  <span dangerouslySetInnerHTML={{__html:t("install.pairingFailedTroubleshoot")}}/>
                              </div>
                              <div> {BtnNext('sensor', t("install.tryAgain"))}</div>
                              <div onClick={()=>setViewLoopSGT('')} className="mt-2 btn btn-dark btn-block w-100 ">{t("install.cancel")}</div>

                              <div className="mt-4">
                                <i>
                                  <i className="fa fa-info-circle"></i> <span dangerouslySetInnerHTML={{__html: t("install.multipleFailures")}}/>
                                </i>
                                <div onClick={()=>ActionNextStep('next')} className="btn btn-block mt-1"><u>{t("install.continueWithInstallation")} <i className="fa fa-angle-right"></i></u></div>
                              </div>
                            </div>
                        </div>    
                    </div> 
                  </div>
                
                  {BtnNext('sensor', t("install.continue"))}
                 </>
               }/>
       </section>


        <div className={Number(stateViewStep) === 9 ? 'show':'hidden'}>
          {Nav(t("install.installationCheck"))}
           <CardBox2 title={t("install.installationCheck")} content={
             <>
                {GuideInfo.Verify(t)}
                {BtnNext('next')}
             </>
           }/>
        </div> 
 
  
          <div className={Number(stateViewStep) === 10 ? 'show':'hidden'}>
            <div className={state.verifiedinActionFinnish ? 'hidden':'show'}>
              {Nav(t("install.confirmInstallation"))}
              <CardBox2 title={t("install.confirmInstallation")} content={
                <>
                  <div className="text-center pt-2 pb-4 bold" dangerouslySetInnerHTML={{__html: t("install.verifyDetails")}}/>

                  <div className="content-box"> 

                        <div className="flex  mt-1 p-0"><div className="w-40 text-left p-2 strong">{t("install.assetId")}</div><div className="w-60 text-right p-2">{state.meterId ? state.meterId:dataAsset.meterId}</div></div>
                        <div className="flex  p-0"><div className="w-40 text-left p-2 strong">{t("install.electricityArea")}</div><div className="w-60 text-right p-2">{state.deliveryArea ? state.deliveryArea:dataAsset.deliveryArea}</div></div>
                        <div className="flex  p-0"><div className="w-40 text-left p-2 strong">{t("install.gridArea")}</div><div className="w-60 text-right p-2">{state.meterGridArea ? state.meterGridArea:dataAsset.meterGridArea}</div></div>

                        <div className="flex line mt-1 p-0"><div className="w-40 text-left p-2 strong">{t("install.address")}</div>
                              <div className="w-60 text-right p-2">
                                {
                                  state.address ? (
                                    state.address
                                  ):(
                                    dataAsset.address && dataAsset.address.address1 ? dataAsset.address.address1:''
                                )}
                                &nbsp;
                                {
                                  state.zip ? (
                                    state.zip
                                  ):(
                                    dataAsset.address && dataAsset.address.zip ? dataAsset.address.zip:''
                                )}
                                &nbsp;
                                {
                                  state.city ? (
                                    state.city
                                  ):(
                                    dataAsset.address && dataAsset.address.city ? dataAsset.address.city:''
                                )}

                              </div>
                        </div>

                        <div className="flex line mt-1 p-0"><div className="w-40 text-left p-2 strong">{t("install.heatPump")}</div><div className="w-60 text-right p-2">
                          {/*{ModelType(dataDeviceModel.type)} {dataDeviceModel.mfr} {dataDeviceModel.model}, {dataDeviceModel.size}kW*/}
                          <ModelType type={state.hpType} /> {state.hpManufacturer} {state.hpModel}, {state.hpSize}kW
                        </div></div>
                        <div className="flex line p-0"><div className="w-40 text-left p-2 strong">{t("install.connectedCables")}</div><div className="w-60 text-right p-2">{state.cable}</div></div>

                        <div className="flex line p-0"><div className="w-40 text-left p-2 strong">{t("install.hardwareId")}</div><div className="w-60 text-right p-2">{dataAsset.clientId}</div></div>
                       
                      
                        <div className="flex line p-0"><div className="w-40 text-left p-2 strong">{t("install.sensor")}</div><div className="w-60 text-right p-2">
                          {state.sensorsdata && state.sensorsdata.length > 0 &&
                              state.sensorsdata.map((item, i) => (
                                <div className="" key={i}>{item.sensorId} {item.model}</div>
                              ))
                          }
                          {state.sensorsdata && state.sensorsdata.length === 0 &&
                            <div className="text-grey">
                              <i>{t("install.noSensor")}</i>
                            </div>
                          }
                        </div>
                      </div>

                  </div>


                  <div className="mt-3">
                    <div className="p-3 text-center">
                      <label onClick={()=>HandleCheckboxVerified()} htmlFor="verified" className="pointer">
                        <input type="checkbox" name="verified" id="verified"  value="1" />  {t("install.confirmCorrect")}
                      </label>
                    </div>
                    <div className={state.verifiedinActionError ? 'show':'hidden'}>
                      <div className="text-danger text-center">
                        <i className="fa fa-warning"></i> {t("install.error")}<br/>
                        <span dangerouslySetInnerHTML={{__html: t("install.checkboxError")}}/>
                      </div>
                    </div>

                    <div onClick={()=>HandleVerified()} id="submitBtn" className="btn btn-primary bold text-big es-bg p-3 w-100 mt-3 text-m d-flex justify-content-center">
                      <div className="mr-2">{t("install.confirm")}</div>
                      <div className={state.verifiedinAction ? 'show':'hidden'}> <span className=" spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></div>
                    </div>
                  </div>


                </>
              }/>
            </div>

            <div className={state.verifiedinActionFinnish ? 'show':'hidden'}>
              <div className="mt-6 p-3 text-primary text-center">
                  <div className="text-large"><i className="fa fa-check"></i></div>
                  <div className="text-big">{t("install.installationComplete")}</div>

                  <p><Link to="/start" className="btn btn-border mt-3">{t("install.finish")}</Link></p>
                    <br/><br/><br/><br/>
               </div>
            </div>


          </div>
        </form>

        <div className={state.verifiedinActionFinnish ? 'hidden':'show'}>
          <ViewStep step={stateViewStep} />
        </div>


        <GuideInfo.Footer />

        {/*
          <div className="flex w-100">
            <div className="w-30">
              <pre>### apiAssetMutation
                 {JSON.stringify(apiAssetMutation, null, 2)}
              </pre>
            </div>
            <div className="w-30">
              <pre>### dataAsset useQuery
               {JSON.stringify(dataAsset, null, 2)}</pre>
            </div>
            <div className="w-30">
               <pre>### useState
               {JSON.stringify(state, null, 2)}</pre>
            </div>
          </div>
        */}


    </>
  );
}

function ViewStep(props){
  //const s = props.step;
  //const step = (s) ? s:"1";

  const userName = (localStorage.getItem("userName")) ? localStorage.getItem("userName"):'';
  const tenantName = (localStorage.getItem("tenantName")) ? localStorage.getItem("tenantName"):'';
  const {t} = useTranslation();

  const navigate = useNavigate();

  const exit = () => {
    // Tips! https://bobbyhadz.com/blog/react-onclick-redirect
    navigate("/start");
  }

  return (
    <>
      {/*
      <ul className="multi-steps mt-5">
       <li className={Number(step)===0 ? ("is-active"):("")}></li>
       <li className={Number(step)===1 ? ("is-active"):("")}></li>
       <li className={Number(step)===2 ? ("is-active"):("")}></li>
       <li className={Number(step)===3 ? ("is-active"):("")}></li>
       <li className={Number(step)===4 ? ("is-active"):("")}></li>
       <li className={Number(step)===5 ? ("is-active"):("")}></li>
       <li className={Number(step)===6 ? ("is-active"):("")}></li>
       <li className={Number(step)===7 ? ("is-active"):("")}></li>
       <li className={Number(step)===8 ? ("is-active"):("")}></li>
     </ul>
     */}

      <div
        id="config-step"
        className="flex-column flex justify-content-center flex-fill w-60 mx-auto"
        style={{ gap: "10px" }}
      >
        <div className="text-center">
          {t("install.account")} <br />
          <strong>{tenantName}</strong>
        </div>
        <div className="text-center">
          {t("install.installer")} <br />
          <strong>{userName}</strong>
        </div>
        <LanguagePicker />
        <div
          aria-hidden="true"
          data-toggle="modal"
          data-target="#exitModal"
          className="btn btn-dark"
        >
          {t("install.finish")} <i className="fa fa-times"></i>
        </div>
        <div
          aria-hidden="true"
          data-toggle="modal"
          data-target="#supportModal"
          className="btn btn-dark"
        >
          {t("install.support")} <i className="fa fa-phone"></i>
        </div>
      </div>

      <ModalBox
        name="exitModal"
        content={
          <div className="mx-auto">
            {t("install.confirmExit")}
            <div className="d-flex justify-content-center p-4">
              <div
                onClick={() => exit()}
                data-dismiss="modal"
                aria-label="Close"
              >
                <Link to="/start" className="btn btn-danger">
                  {t("install.yes")}
                </Link>
              </div>
              <div className="w-10"></div>
              <div
                className="btn btn-light"
                data-dismiss="modal"
                aria-label="Close"
              >
                {t("install.no")}
              </div>
            </div>
          </div>
        }
      />
      <ModalBox
        name="supportModal"
        content={
          <>
            <GuideInfo.Support
              title={t("install.supportTitle")}
              description={t("install.supportDescription")}
            />
          </>
        }
      />
    </>
  );
} 
  
function ModalBox(props){
  const name = props.name;
  return (
    <>
      <div aria-labelledby={name} aria-hidden="true" className="modal no-border fade mt-6" id={name} tabIndex="-1" role="dialog">
            <div className="modal-dialog no-border" role="document">
                <div className="modal-content  content-box">
                    <div className="modal-header no-border">
                        <span className="close pointer" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true" className="text-white">×</span>
                        </span>
                    </div>
                    <div className="p-3">
                      {props.content}
                    </div>
                </div>
            </div>
      </div>
    </>
  ) 
}   
    
function CardBox2(props) {
  return (
    <div className="container-step w-100">
      {/* Card Header - Dropdown */}
       <div className="text-center bold text-primary hidden"><h4>{props.title}</h4></div>
       <div className="no-border">
         {props.content}
       </div>
    </div>
  );
}


// Check for type name
function ModelType(props) {
  const {t} = useTranslation();
  const type = props.type;
  var typeDesc = '';
  switch(type) {
    case "GSHP" : typeDesc = t("install.mountainLandLake"); break;
    case "ASHP" : typeDesc = t("install.airWater"); break;
    case "EAHP" : typeDesc = t("install.exhaustAir"); break;
    default: typeDesc = t("install.unspecifiedType"); break;
  }
  return typeDesc;
}

/*
mfr
:
"Bosch"
model
:
"Compress 5000"
ohmigoType
:
0
shortModelId
:
"OvkwQ_22Y"
size
:
"7LW / 7LW/M"*/
function DeviceMatchQuery(item, q) {
  var resp = false;
  var query = q.toLowerCase();
  const minLength = 2;

  var find_mfr;
  if(query.length > minLength && item.mfr) {
    let text_mfr = item.mfr;
    let text_low_mfr = text_mfr.toLowerCase();
        find_mfr = text_low_mfr.includes(query);
  }

  var find_model;
  if(query.length > minLength && item.model) {
    let text_model = item.model;
    let text_low_model = text_model.toLowerCase();
        find_model = text_low_model.includes(query);
  }

  var find_size;
  if(query.length > minLength && item.size) {
    let text_size = item.size;
    let text_low_size = text_size.toLowerCase();
        find_size = text_low_size.includes(query);
  }


  // match
  if(find_mfr || find_model || find_size) {
    resp = true;
  }

  return resp;
}

function DeviceSearch(props) {
  const query = (props.search) ? props.search:'';
  const dataHPmodels = localStorage.getItem("deviceModel");
  const data = JSON.parse(dataHPmodels);
  const {t} = useTranslation();

  return(
    <div className="mt-3 text-2 p-2">
      {data && data.length &&
        data.map((item, i) => (
          <div key={i}>
            {DeviceMatchQuery(item, query) &&
              <div onClick={()=>props.callback(item.deviceModelId, item.type, item.mfr, item.model, item.size, item.docId)} className="relative pointer p-2 line " data-dismiss="modal" aria-label="Close">
               <ModelType type={item.type} /> <br/>
               <strong>{item.mfr} <span className="nowrap">{item.model}</span></strong>
               <br/>{t("install.size")}(<strong>{item.size}</strong>)
              </div>
            }
          </div>
        ))
      }
      <br/><br/><br/><br/>
    </div>
  )
}
